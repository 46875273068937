import * as React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

const ComponentWrapper = styled.div`
  --offBlack: #1c2224;
  @import url("https://use.typekit.net/ryn0bdm.css");
  background-size: cover;
  border: 3px solid #ef9922;
  cursor: help;
  width: 100%;
  max-width: 300px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  .details {
    background: #ef9922;
    box-sizing: border-box;
    font-family: interface,ui-sans-serif,sans-serif;
    text-align: center;
    width: 100%;
    max-width: 300px;
    height: 200px;
    transition: ease-in-out .2s transform;
    transform: translateY(200px);
    padding: 2rem;
    a {
      font-weight: 700;
      &:hover {
        text-decoration: underline 1px wavy var(--offBlack);
      }
    }
    .title {
      color: var(--offBlack);
      font-family: p22-mackinac-pro,ui-serif,serif;
      font-size: 18px;
    }
    .stakeholders {
      color: white;
      opacity: .6;
      font-size: 14px;
    }
  }
  &:hover {
    .details {
      transform: translateY(88px);
    }
  }
`;

function Card({ image, title, description, url, stakeholders }) {
  return (
    <ComponentWrapper 
      style={{
        backgroundImage: `url(${image})`,
      }} 
      className="component card">
      <div className="details">
        <a className="title" href={url}>{title}</a>
        <div className="stakeholders">{stakeholders.join(', ')}</div> 
      </div>
    </ComponentWrapper>
  )
}

Card.defaultProps = {
  description: ``,
  stakeholders: [],
  url: ``,
}

Card.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  stakeholders: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default Card
